import React from "react";
import TitleImageComponent from "../../components/image/TitleImageComponent";
import browserHistory from "../../helper/browserHistory";
import EditSimpleValueComponent from "../../components/moap-components/EditSimpleValueComponent";
import {inject} from "mobx-react";
import config from "../../config/main.config";
//import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import Breadcrumb from "semantic-ui-react/dist/commonjs/collections/Breadcrumb";
import EditHTMLComponent from "../../components/moap-components/EditHTMLComponent";

const PAGE_LINK = "Dreps";

@inject("stores")
class DrepsPage extends React.Component {

    constructor(props) {
        super(props);
            this.state = {
                textStore: props.stores.textStore.texts,
                isLoading: true
            }
    }

    componentDidMount() {
        this.props.stores.trackingStore.addVisit(PAGE_LINK);
        window.scrollTo(0, 0);

    }

    render() {
        const Seperator = "https://www.buergerstiftung-kreis-rv.de/images/seperator.png";

        return (
            <Grid>
                <TitleImageComponent
                    textStore={this.state.textStore}
                    namespace={'dreps'}
                    imageLinkId={"DrepsPage-title-image-id"}
                    headerId={"DrepsPage-title-header-id"}
                    subheaderId={"DrepsPage-subtitle-header-id"}
                    small
                />
                <Grid.Row>
                    <Grid.Column width={2} only={'computer'}/>
                    <Grid.Column computer={14} tablet={16} mobile={16}>
                        <Breadcrumb className={"breadcrumb-container"}>
                            <Breadcrumb.Section className={"inactive-breadcrumb"} onClick={() => {
                                browserHistory.push("/");
                                this.setState({});
                            }}>
                                HOME</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron'/>
                            <Breadcrumb.Section className={"active-breadcrumb"}>DREPS STIFTUNG</Breadcrumb.Section>
                        </Breadcrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} textAlign={"center"}>
                        <Image centered
                               src={Seperator}
                               size={"tiny"}
                        />
                        <h2><EditSimpleValueComponent textStore={this.state.textStore}
                                                                                id={"DrepsPage-title-sector-1-id"}
                                                                                namespace={'dreps'}/>
                        </h2>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered className={"page-row-bottom"}>
                    <Grid.Column computer={12} tablet={16} mobile={16} style={{borderStyle: 'none none none none'}}>
                        <p className={"text-block"}>
                           <EditHTMLComponent textStore={this.state.textStore}
                                                                         id={"DrepsPage-icon-card-1-subheader-id"}
                                                                         namespace={'dreps'}/>
                        </p>
                        <Button circular className={"call-to-action-button"}
                                onClick={() => {
                                    window.open('https://www.buergerstiftung-kreis-rv.de/downloads/12_BSKR_DREPS-Stiftung.pdf', "_blank")
                                }}>
                            {"Bewerbungsunterlagen".toUpperCase()}
                        </Button>
                        <p className={"text-block"} style={{marginTop: '4rem'}}>
                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                                id={"DrepsPage-icon-card-3-subheader-id"}
                                                                                namespace={'dreps'}/>
                        </p>

                        <Grid.Row columns={1}>
                            <Grid.Column computer={16} tablet={16} mobile={16} >
                                <h2 style={{textAlign:'center', margin:'1rem auto 5rem auto'}}>
                                    <EditSimpleValueComponent textStore={this.state.textStore}
                                                              id={'DrepsPage-quote-header-1'}
                                                              namespace={'dreps'}/>
                                </h2>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16} mobile={16}>
                            <img className={'quote-image'} src={config.PUBLIC_URL + "images/DREPS_Bubbles_Homepage.jpeg"}
                                 alt={'Dreps Zitate'}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                </Grid.Row>
            </Grid>
        );
    }
}

export default DrepsPage


/*
                            <Grid.Row centered>
                                <Grid.Column computer={12} tablet={16} mobile={16} style={{textAlign: 'center', display:'contents'}}>
                                    <div className={'quote-image'} style={{
                                        backgroundImage: "url(" + config.PUBLIC_URL + "images/iconset/DREPS_Sprechblase1.svg)",
                                        marginBottom: '100px',
                                    }}>
                                        <p className={'text-block quote-text-container'}>
                                            {!this.state.isLoading &&
                                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                      id={"DrepsPage-quote-text-1"}
                                                                      namespace={'dreps'}/>}
                                        </p>
                                    </div>
                                    <div className={'quote-image'} style={{
                                        backgroundImage: "url(" + config.PUBLIC_URL + "images/iconset/DREPS_Sprechblase2.svg)",
                                        marginBottom: '100px',
                                    }}>
                                        <p className={'text-block quote-text-container'}>
                                            {!this.state.isLoading &&
                                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                      id={"DrepsPage-quote-text-2"}
                                                                      namespace={'dreps'}/>}
                                        </p>
                                    </div>
                                    <div className={'quote-image'} style={{
                                        backgroundImage: "url(" + config.PUBLIC_URL + "images/iconset/DREPS_Sprechblase4.svg)",
                                        marginBottom: '100px',
                                    }}>
                                        <p className={'text-block quote-text-container'}>
                                            {!this.state.isLoading &&
                                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                      id={"DrepsPage-quote-text-4"}
                                                                      namespace={'dreps'}/>}
                                        </p>
                                    </div>
                                    {/*
                                     <div className={'quote-image'} style={{
                                        backgroundImage: "url(" + config.PUBLIC_URL + "images/iconset/DREPS_Sprechblase6.svg)",
                                    }}>
                                        <p className={'text-block quote-text-container'} style={{marginLeft: '7vw'}}>
                                            {!this.state.isLoading &&
                                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                      id={"DrepsPage-quote-text-3"}
                                                                      namespace={'dreps'}/>}
                                        </p>
                                    </div>

                                    <div className={'quote-image'} style={{
                                        backgroundImage: "url(" + config.PUBLIC_URL + "images/iconset/DREPS_Sprechblase5.svg)",
                                    }}>
                                        <p className={'text-block quote-text-container'}>
                                            {!this.state.isLoading &&
                                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                      id={"DrepsPage-quote-text-5"}
                                                                      namespace={'dreps'}/>}
                                        </p>
                                    </div>

                                    <div style={{
                                        backgroundImage: "url(" + config.PUBLIC_URL + "images/iconset/DREPS_Sprechblase3.svg)",
                                        backgroundSize: '100% 100%',
                                        backgroundPositionY: 'center',
                                        height: '500px'
                                    }}>
                                        <p className={'text-block'} style={{position:'relative', top:'20%',padding:'1vw', maxWidth:'300px', margin:'auto'}}>
                                            {!this.state.isLoading &&
                                            <EditSimpleValueComponent textStore={this.state.textStore}
                                                                      id={"DrepsPage-quote-text-6"}
                                                                      namespace={'dreps'}/>}
                                        </p>
                                    </div>

                                </Grid.Column>
                            </Grid.Row>
                */